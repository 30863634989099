"use strict";

/**
 * Created by sguilly on 23/08/16.
 */
(function () {
  "use strict";

  localStorage.setItem("debug", "");

  var models = angular.module("models");

  if (window.location.href.indexOf("localhost") != -1) {
    var url = "http://localhost:12000/api";
    var urlNx = "http://localhost:4030/api";
    var urlPdf = "https://htmltopdf.lvr-fleet.fr/v1/generate";
    var urlDepaForm = "http://localhost:4001";
    var urlManagerAct = "http://localhost:4005";
    var urlApp = "http://localhost:4202";
    var urlExportXlsx = "http://localhost:4003";
    var urlAdmin = "http://localhost:3001";
  } else if (window.location.href.indexOf("192.168") != -1) {
    var url = "http://localhost:12000/api";
    var urlNx = "http://localhost:4030/api";
    var urlPdf = "https://htmltopdf.lvr-fleet.fr/v1/generate";
    var urlDepaForm = "http://localhost:4001";
    var urlManagerAct = "http://localhost:4005";
    var urlApp = "http://localhost:4202";
    var urlExportXlsx = "http://localhost:4003";
    var urlAdmin = "http://localhost:3001";
  } else {
    var url = window.location.origin + "/api";
    var urlPdf = "https://htmltopdf.lvr-fleet.fr/v1/generate";
    var urlDepaForm = "https://pwa.drm-fleet.eu/depa-form";
    var urlManagerAct = "https://pwa.drm-fleet.eu/manager-act";
    var urlExportXlsx = "https://pwa.drm-fleet.eu/export-xlsx";

    if (window.location.href.includes("-test")) {
      var urlAdmin = "https://pwa.drm-fleet.eu/admin-test";
      var urlNx = "https://app-test.lvr-fleet.fr/api";
      // TODO: Test version of the app :
      var urlApp = "https://app.lvr-fleet.fr";
    } else if (window.location.href.includes("-beta")) {
      var urlAdmin = "https://pwa.drm-fleet.eu/admin-beta";
      var urlNx = "https://app-beta.lvr-fleet.fr/api";
      var urlApp = "https://app-beta.lvr-fleet.fr";
    } else {
      var urlAdmin = "https://pwa.drm-fleet.eu/admin";
      var urlNx = "https://app.lvr-fleet.fr/api";
      var urlApp = "https://app.lvr-fleet.fr";
    }
  }

  models.constant("SERVER_URL", url);
  models.constant("SERVER_NX_URL", urlNx);
  models.constant("DEPA_FORM_URL", urlDepaForm);
  models.constant("MANAGER_ACT_URL", urlManagerAct);
  models.constant("APP_URL", urlApp);
  models.constant("EXPORT_XLSX_URL", urlExportXlsx);
  models.constant("ADMIN_URL", urlAdmin);
  models.constant("PDF_URL", urlPdf);

  models.config(["$provide", function ($provide) {
    var log = debug("config:models");

    log("enter");
  }]);
})();