"use strict";

angular.module("models").service("GroupsService", ["LbService", "$rootScope", function (LbService, $rootScope) {
  var that = this;

  var params = {
    name: "groups",
    attributesForCache: ["name", "parent", "backgroundColor", "inactive", "ccUsers"],

    formatName: function formatName(element) {
      if (element.error === 404 && !["super-admin", "super-operator", "operator", "user-admin", "hr"].includes($rootScope.$user.role)) {
        return "Masqué (hors périmètre)";
      } else if (element.error === 404) {
        return "Supprimé";
      }
      var name = "";

      if (element.inactive) {
        name += "[GROUPE DÉSACTIVÉ] ";
      }

      if (element.parent) {
        name += element.parent + " > ";
      }

      name += element.name ? element.name : "N.C.";
      return name;
    }
    //defaultOrder: 'date DESC'
  };

  angular.extend(that, LbService.getModelBis(params));
}]);